import { format } from "date-fns";
import { zhCN, ja, sv, enUS } from "date-fns/locale";

/**
 * Resolves the provided date input (either string or timestamp) to a localized date string format.
 * It ensures the date is interpreted in UTC and then formats it using the provided locale (if any).
 */
export const resolveDate = (date, locale?: string) => {
  if (!date) return "";

  let parsedDate;

  try {
    // If the date is a string in the format "YYYY-MM-DD HH:mm", parse it as UTC.
    if (
      typeof date === "string" &&
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(date)
    ) {
      const [datePart, timePart] = date.split(" ");
      const [year, month, day] = datePart.split("-").map(Number);
      const [hours, minutes] = timePart.split(":").map(Number);
      parsedDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));
    } else {
      parsedDate = new Date(date);

      if (parsedDate.getTimezoneOffset() !== 0) {
        // Adjust the date to UTC by setting the offset
        parsedDate.setMinutes(
          parsedDate.getMinutes() - parsedDate.getTimezoneOffset()
        );
      }

      // Check if the parsed date is invalid.
      if (isNaN(parsedDate.getTime())) {
        return "Invalid Date";
      }
    }
  } catch (error) {
    // Handle errors during date parsing.
    return "Invalid Date";
  }

  if (locale) {
    const dateLocales = {
      sv,
      en: enUS,
      ja,
      zh: zhCN,
    };

    try {
      let dateFormat = "MMMM d, yyyy";

      // Define the format used for Swedish, e.g., 17 November, 2023
      if (locale === "sv") {
        dateFormat = "d MMMM, yyyy";
      }

      const formattedDate = format(parsedDate, dateFormat, {
        locale: dateLocales[locale] || enUS,
      });

      // Replace occurrences of "st," "nd," "rd," or "th" after a digit with just the digit.
      return formattedDate.replace(/(\d)(st|nd|rd|th)/, "$1");
    } catch (error) {
      // Handle errors during date formatting.
      return "Invalid Date";
    }
  }

  // Handle cases where no locale is provided.
  try {
    const formattedDate = format(parsedDate, "MMMM d, yyyy");

    // Replace occurrences of "st," "nd," "rd," or "th" after a digit with just the digit.
    return formattedDate.replace(/(\d)(st|nd|rd|th)/, "$1");
  } catch (error) {
    // Handle errors during date formatting.
    return "Invalid Date";
  }
};
